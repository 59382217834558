<script lang="ts" setup>
import type { Movie, MovieInterface } from '#gql/default'

interface Props {
  slide: Movie | MovieInterface
  index?: number | null
  hideLink?: boolean
  renderAboveTheFold: boolean
  classes?: {
    link?: string | object | []
    title?: string | object | []
    description?: string | object | []
  }
}

const props = withDefaults(defineProps<Props>(), {
  classes: () => ({}),
  index: 0,
})

const { t } = useI18n()
const image = getImage(props.slide, 'heroImage', props.slide.title)

const { bgColor, textColor } = useGetReadableColorCombination({
  bgColors: image.colors!,
  textColors: ['rgb(255,255,255)', 'rgb(0,0,0)'],
  alpha: 0.75,
})

const config = useAppConfig()
const imageConfig = computed(() => ({
  ...config.images.gallery.carousel,
  loading: props.renderAboveTheFold ? IMAGE_LOADING.EAGER : IMAGE_LOADING.LAZY,
}))

defineOptions({
  name: 'CarouselSlideMovie',
})
</script>

<template>
  <MovieLink :movie="slide" :disabled="hideLink">
    <CarouselImage
      :image
      :image-format="IMAGE_FORMAT.LANDSCAPE"
      :image-config="imageConfig"
    />
    <div
      class="space-y-4 p-4 transition-all duration-700 ease-in-out sm:absolute sm:bottom-8 sm:left-8 sm:w-auto sm:min-w-[20rem] sm:max-w-md sm:rounded-b-lg sm:rounded-t-lg sm:bg-[var(--slide-background)] sm:shadow-lg sm:backdrop-blur-sm"
      :style="`--slide-background: ${bgColor}; --slide-text: ${textColor};`"
    >
      <div
        class="text-lg font-medium sm:text-[var(--slide-text)]"
        v-text="slide.title"
      />
      <div
        class="text-base sm:text-[var(--slide-text)]"
        v-text="slide.shortDescription"
      />

      <KButton
        v-if="!hideLink"
        :theme="BUTTON_THEME.SECONDARY"
        :title="t('btn.tickets')"
        :text="t('btn.tickets')"
        class="sm:!border-[var(--slide-text)] sm:!text-[var(--slide-text)]"
      />
    </div>
  </MovieLink>
</template>

<i18n>
de:
  btn:
    tickets: "Zum Film"
es:
  btn:
    tickets: "Ver película"
</i18n>
