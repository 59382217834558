interface Color {
  r: number
  g: number
  b: number
}

interface ColorCombination {
  bgColor: Ref<string>
  textColor: Ref<string>
}

export function useGetReadableColorCombination({
  bgColors,
  textColors,
  alpha = 1,
}: {
  bgColors: string[]
  textColors: string[]
  alpha?: number
}): ColorCombination {
  const bgColor: Ref<string> = ref('')
  const textColor: Ref<string> = ref('')

  // Function to convert RGB string to object
  const rgbStringToObject = (rgbString: string): Color => {
    const [r, g, b] = rgbString.match(/\d+/g)!.map(Number)
    return { r, g, b }
  }

  const getContrastRatio = (color1: Color, color2: Color): number => {
    const luminance = (color: Color): number => {
      const a = [color.r, color.g, color.b].map((v) => {
        v /= 255
        return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4)
      })
      return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722
    }
    const lum1 = luminance(color1)
    const lum2 = luminance(color2)
    const brightest = Math.max(lum1, lum2)
    const darkest = Math.min(lum1, lum2)
    return (brightest + 0.05) / (darkest + 0.05)
  }

  const findBestContrastCombo = (
    fgColorStrings: string[],
    bgColorStrings: string[],
  ): { fgColor: Color; bgColor: Color; contrastRatio: number } | null => {
    let bestCombo: {
      fgColor: Color
      bgColor: Color
      contrastRatio: number
    } | null = null
    let highestContrastRatio = 0

    const fgColors = fgColorStrings.map(rgbStringToObject)
    const bgColors = bgColorStrings.map(rgbStringToObject)

    fgColors.forEach((fgColor) => {
      bgColors.forEach((bgColor) => {
        const contrastRatio = getContrastRatio(fgColor, bgColor)
        if (contrastRatio > highestContrastRatio) {
          highestContrastRatio = contrastRatio
          bestCombo = { fgColor, bgColor, contrastRatio }
        }
      })
    })

    return bestCombo
  }

  // Convert your color strings to objects
  const bestCombo = findBestContrastCombo(textColors, bgColors)
  if (bestCombo) {
    // Convert back to string for easy use in style bindings
    bgColor.value = `rgba(${bestCombo.bgColor.r}, ${bestCombo.bgColor.g}, ${bestCombo.bgColor.b}, ${alpha})`
    textColor.value = `rgb(${bestCombo.fgColor.r}, ${bestCombo.fgColor.g}, ${bestCombo.fgColor.b})`
  }

  return { bgColor, textColor }
}
