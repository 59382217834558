<script lang="ts" setup>
import type { CarouselSlide } from '@base/types/carousel'

interface Props {
  slide: CarouselSlide
  index?: number | null
  active?: boolean
  renderAboveTheFold?: boolean
  renderTrailer?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  index: null,
  renderAboveTheFold: false,
  renderTrailer: false,
  active: false,
})

const remoteVideoId = getYoutubeRemoteVideoId(props.slide.videoUrl)

defineOptions({
  name: 'CarouselSlideVideo',
})
</script>

<template>
  <div class="relative">
    <YoutubePlayer
      v-if="renderTrailer && remoteVideoId"
      :remote-video-id="remoteVideoId"
      v-bind="slide.videoPlayerParams"
      :pause="!active"
      class="h-full w-full overflow-hidden"
    />

    <YoutubeThumbnail
      v-else-if="remoteVideoId"
      :remote-video-id
      class="absolute inset-0 h-full w-full object-cover"
    />
  </div>
</template>
