<script lang="ts" setup>
import type { CarouselSlide } from '@base/types/carousel'

interface Props {
  slide: CarouselSlide
  index?: number | null
  active?: boolean
  hideTitle: boolean
  hideDescription: boolean
  hideLink: boolean
  renderAboveTheFold?: boolean
  renderTrailer?: boolean
  classes?: {
    link?: string | object | []
    title?: string | object | []
    description?: string | object | []
  }
}

withDefaults(defineProps<Props>(), {
  classes: () => ({}),
  index: 0,
  active: false,
  renderTrailer: false,
  renderAboveTheFold: false,
})

const emit = defineEmits(['touchstart'])

function handleTouchStart() {
  emit('touchstart')
}

defineOptions({
  name: 'CarouselSlide',
})
</script>

<template>
  <div
    class="dark:text-light text-dark bg-neutral-light-1 dark:bg-neutral-dark-2 relative flex flex-grow flex-col sm:bg-transparent"
    @touchstart.passive="handleTouchStart"
  >
    <CarouselSlideImage
      v-if="!slide.type || slide.type === CAROUSEL_SLIDE_TYPE.IMAGE"
      :slide
      :classes
      :index
      :hide-title
      :hide-description
      :hide-link
      :render-above-the-fold
    />
    <CarouselSlideVideo
      v-else-if="slide.type === CAROUSEL_SLIDE_TYPE.VIDEO"
      :slide
      :active
      :render-trailer
      :render-above-the-fold
      :index
    />
    <CarouselSlideMovie
      v-else-if="slide.type === CAROUSEL_SLIDE_TYPE.MOVIE"
      :slide
      :classes
      :active
      :hide-link
      :render-above-the-fold
      :index
    />
  </div>
</template>
